<template>
  <t-page>
    <h3 slot="header">
      {{ $t('fields.help') }}
    </h3>

    <div class="help-page">
      <h2>{{ $t('pages.help.sign_up.heading') }}</h2>
      <a class="help-page__contact" :href="`mailto:${tenantSupportEmail}`">{{
        tenantSupportEmail
      }}</a>
      <h3>
        <i18n path="pages.help.sign_up.lead" class="text--grey">
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          <a href="https://ausbildungsradar.de" target="_blank">{{ $t('fields.website') }}</a>.
        </i18n>
      </h3>
    </div>
  </t-page>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  computed: {
    tenantSupportEmail() {
      return this.$store.getters['tenant/tenant'].meta.support_email;
    },
  },
});
</script>

<style lang="scss" scoped>
.help-page {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
  }

  &__contact {
    font-size: 4rem;
  }

  a {
  }
}
</style>
